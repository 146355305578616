import { Link } from 'gatsby';
import styled from "styled-components"

const StyledLink = styled(Link)`
    color: #234068;
    &:hover {
        color: blue;
        text-decoration: underline;
    }
`

export default StyledLink